import React, {useContext } from "react"
import {graphql} from 'gatsby'
import {FirebaseContext} from '@components/Firebase'
import Seo from "@components/Seo"
import { useTranslation } from 'react-i18next'
import Header from "@menus/header"
import {useObjectifyStoryArray} from '@hooks/useObjectifyStoryArray'
import {CTAWave, StorySlider, AppPageHeader, ListViewItem} from '@common'
import HeroMenu  from '@menus/HeroMenu'
import styled from 'styled-components'
import HomeHeroSection from '@loggedOut/HomeHeroSection'

const CategoryWrapper = styled.div`
  padding: 5% 5%;
  background-color: ${props => props.theme.backgroundColor};
`

const StoryFantasyTemplate = (props) => {
  const {accent, contentIds } = props.pageContext
  const lang = props.pageContext.lang
  const storiesObject = useObjectifyStoryArray(props.data.allStory)
  const stories = props.data.allStory.edges
  const { t } = useTranslation()
  const {user} = useContext(FirebaseContext);
  
  const seoTitle =  t('story-fantasy-template.seoTitle', { accent: props.pageContext.accent })
  const seoDescription = t('story-fantasy-template.seoDescription', { accent: props.pageContext.accent })

  return(
    <>
      <AppPageHeader pageTitle={`${t('story-accent-template.title')}: ${accent}`}/>
      <Header location={props.location}/> 
      <Seo description={seoDescription} title={seoTitle}/>
     {!!user ? 
        <CategoryWrapper>
        {stories && contentIds.map(id =>(
          <ListViewItem
            id={id}
            slug={storiesObject[id][lang].slug}
            title={storiesObject[id][lang].title}
            sampleAudioRef={storiesObject[id][lang].sampleAudioRef}
            storyCover={storiesObject[id].localImage.childImageSharp.gatsbyImageData}
            thumbnail={storiesObject[id].localImage.smallImage.gatsbyImageData}
            duration={storiesObject[id][lang].duration}
            voices={storiesObject[id][lang].voices}
            mainFantasy={storiesObject[id].mainFantasy[lang].fantasy}
            spiciness={storiesObject[id].spiciness}
            premiumContent={storiesObject[id][lang].premiumContent}
            audiodesiresOriginal={storiesObject[id].audiodesiresOriginal}
            key={id}
          />
        ))} 
        </CategoryWrapper >
      :
        <>
          <HeroMenu/>
          <HomeHeroSection 
            headerTitle={ `${accent} ${t('story-accent-template.heroTitle')}`} 
            headerText={t('story-accent-template.heroText')} 
            ctaText={t('story-accent-template.ctaButton')}/>
    
          <StorySlider
            title={t('story-accent-template.sliderTitle-1')} 
            subtitle = {t('story-accent-template.sliderSubtitle-1')}
            stories={storiesObject}
            contentIds={contentIds}
          /> 
          <CTAWave 
            title ={t('story-accent-template.ctaTitle')}
            buttonText={t('story-accent-template.ctaButton')}
          />
      </>
      } 
    </>
  )
 };


// get proper filter query working
export const query = graphql`
query AccentTemplate($lang: String, $isEn: Boolean!, $isEs: Boolean!, $isDe: Boolean!){
  allStory {
    edges {
      node {
        ...allStoryInfo
      }
    }
  }
  allAccent(filter: {language: {eq: $lang}}){
    edges{
      node{
        id
        accent
        language
        dateCreated
        slug
      }
    }
  }
  dummyToUseVarInFragment: allStory(filter: {id: {eq: "nevertrue"}}){
    edges {
      node{
        id @include(if: $isEs)
        id @include(if: $isEn)
        id @include(if: $isDe)
      }
    }
  }
}
 
` 


export default StoryFantasyTemplate;